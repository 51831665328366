import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import Button from "components/Button/Button";
import Card from "components/Card/Card";
import CheckBox from "components/CheckBox/CheckBox";
import Input from "components/Input/Input";
import PageLoading from "components/PageLoading/PageLoading";
import PageLoadingError from "components/PageLoadingError/PageLoadingError";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getUserData, retryFn, saveUserData } from "utils/api";
import Alert from "../../components/Alert/Alert";
import Modal from "../../components/Modal/Modal";
import { pageURL } from "../routes";
import Articles from "./Articles";
import KeywordProjects from "./KeywordProjects";
import GlossaryProjects from "./GlossaryProjects";
import AutomationProjects, { AutomationProject } from "./AutomationProjects";
import BlogFinder, { BlogFinderTableProps } from "./BlogFinder";
import BlogFinderProject, { BlogFinderBulkTableProps } from "./BlogFinderProject";
import "./ManageUser.scss";
import ManageUserWebsite from "./ManageUserWebsite";
import Integration from "./Integrations"
import InternalLinks from "./InternalLinks";
import { InternalLink } from "./InternalLinks";
import GuestPostFinder from "./GuestPostFinder";
import { Query } from "./GuestPostFinder";
import { RedditQuery } from "./RedditPostFinder";
import RedditPostFinder from "./RedditPostFinder";

interface UserData {
	username: string
	email: string
	email_verified: boolean
	country: string
	date_joined: string
	last_login: string
	user_tz: string
	titles_generated: number
	total_titles_generated: number
	blog_emails_found: number
	total_blog_emails_found: number
	articles_generated: number
	total_articles_generated: number
	send_notification_emails: boolean
	current_active_website: string
	all_connected_websites: UserWebsite[]
	website_logs: WebsiteLogs[]
	current_plan: string
	k8_tasks: Array<string>
	articles: ArticleTitle[]
	keyword_projects: KeywordProjects[]
	glossary_projects: GlossaryProjects[]
	survey_details: Survey[],
	website_connection_limit: number,
	keywords_generated: number,
	total_keywords_generated: number,
	total_glossary_words_generated: number,
	this_month_keywords_count: number,
	this_month_glossary_count: number,
	website_count: number,
	automation_projects: AutomationProject[],
	blog_finder: BlogFinderTableProps[],
	blog_finder_project: BlogFinderBulkTableProps[],
	total_automation_projects: number,
	ltd_plan_activation_dates: LTDPlanActivationDates[],
	all_website_internal_links_data: AllWebsiteInternalLinkData[],
	guest_post_finder_queries: Query[],
	this_month_guest_queries_count: number,
	total_guest_queries_count: number,
	reddit_post_finder_queries: RedditQuery[],
	this_month_reddit_queries_count: number,
	total_reddit_queries_count: number,
}

// 'keywords_generated': user.keywords_generated,
//             'total_keywords_generated': user.total_keywords_generated,
//             'website_count': user.website_set.count(),

interface UserWebsite {
	id: number
	name: string
	domain: string
	logo: string
	image_source: "no_image" | "unsplash" | "google" | "ai_image_generation"
}

interface Survey {
	question: string
	answer: string
}

interface WebsiteLogs {
	id: number
	website_domain: string
	date: string
	connection_type: null | 'connected' | 'disconnected'
	message: string
}

interface ArticleTitle {
	articleUID: string
	articleTitle: string
	keyword: string
	keywordTraffic: number | null
	internalLinks: number | null
	externalLinks: number | null
	images: number | null
	wordCount: number | null
	isProcessing: boolean
	isGenerated: boolean
	isPosted: boolean
	isFailed: boolean
	isArchived: boolean
	postLink: string
	isUserAdded: boolean
	postedTo: string
	feedback: string
	postedOn: string
}

interface LTDPlanActivationDates {
	plan_name: string,
	activated_on: string
}

interface KeywordProjects {
	projectName: string
	totalKeywords: number
	totalTrafficVolume: number
	dateCreated: string
	projectId: string
	locationIsoCode: string
}

interface AllWebsiteInternalLinkData {
	domain: string,
	internal_links: InternalLink[],
	is_website_crawling: boolean
}

interface GlossaryProjects {
	projectName: string;
	glossaryWord: string;
	glossaryWords: string[];
	count: number;
	projectId: string;
}

export default function ManageUser() {
	// --------------------- REACT ROUTER PARAMS ---------------------
	let { userId } = useParams();

	// --------------------- STATES ---------------------
	const [userData, setUserData] = useState<UserData>({
		username: "",
		email: "",
		email_verified: false,
		country: "",
		date_joined: "",
		last_login: "",
		user_tz: "",
		titles_generated: 0,
		articles_generated: 0,
		total_titles_generated: 0,
		blog_emails_found: 0,
		total_blog_emails_found: 0,
		total_articles_generated: 0,
		send_notification_emails: false,
		current_active_website: "",
		all_connected_websites: [],
		website_logs: [],
		current_plan: "",
		k8_tasks: [],
		articles: [],
		keyword_projects: [],
		glossary_projects: [],
		survey_details: [],
		website_connection_limit: 0,
		keywords_generated: 0,
		total_keywords_generated: 0,
		total_glossary_words_generated: 0,
		this_month_keywords_count: 0,
		this_month_glossary_count: 0,
		website_count: 0,
		automation_projects: [],
		total_automation_projects: 0,
		ltd_plan_activation_dates: [],
		all_website_internal_links_data: [],
		guest_post_finder_queries: [],
		this_month_guest_queries_count: 0,
		total_guest_queries_count: 0,
		reddit_post_finder_queries: [],
		this_month_reddit_queries_count: 0,
		total_reddit_queries_count: 0,
		blog_finder: [],
		blog_finder_project: []
	});
	const [tasksModal, setTasksModal] = useState(false);
	const [taskSearch, setTaskSearch] = useState("");

	// --------------------- QUERY ---------------------
	const {
		isLoading,
		isError,
		error,
		data,
		refetch
	} = useQuery({
		queryKey: ['getUserData', userId],
		queryFn: () => getUserData(userId!),
		refetchOnWindowFocus: false,
		retry: retryFn
	})

	// --------------------- MUTATIONS ---------------------
	const saveUserDataMut = useMutation({
		mutationKey: ['saveUserData'],
		mutationFn: saveUserData,
		gcTime: 0,
		onSuccess: () => {
			successAlert.current?.show("All user details have been saved successfully!");
		},
		onError: (error) => {
			errorAlert.current?.show(`Failed to save user data: ${error}`);
		},
	})

	// --------------------- EFFECTS ---------------------
	useEffect(() => {
		if (data) {
			setUserData(data['data']);
		}
	}, [data]);

	// --------------------- REFS ---------------------
	const errorAlert = useRef<any>(null);
	const successAlert = useRef<any>(null);

	// --------------------- FUNCTIONS ---------------------
	function refetchUserData() {
		refetch().then();
	}

	function taskFilter() {
		return userData.k8_tasks.filter(value => {
			return value.includes(taskSearch);
		})
	}

	const shortSurveyQ = ["Role", "Experience", "AI tool used", "Abun usage", "Interested feature"]
	// ============================================================
	// --------------------- MAIN RENDER CODE ---------------------
	// ============================================================
	// if (!userId) return <PageLoadingError message={"User ID value is missing"}/>;

	if (isLoading) return <PageLoading />;

	if (isError) return <PageLoadingError message={error as unknown as string} />;

	return (
		<>
			{/* ******************* Tasks Modal ******************* */}
			<Modal active={tasksModal}
				headerText={""}
				closeable={true}
				hideModal={() => setTasksModal(false)}>
				<div className={"mt-5"}>
					<Input type={"text"}
						value={taskSearch}
						placeholder={"Search tasks..."}
						onChange={e => setTaskSearch(e.target.value)} />
					<hr className={"dropdown-divider mt-4 mb-4"} />
					<div className={"website-task-list"}>
						{taskFilter().length === 0 && <p className={"has-text-centered"}>No Tasks Found</p>}
						{taskFilter().map(task => (
							<Link to={pageURL['taskLogs']
								.replace(":userId", userId ? userId.toString() : "Not Found")
								.replace(":jobId", task)}
								className={"website-task-list--item"}
								key={task}>
								{task}
							</Link>
						))}
					</div>
				</div>
			</Modal>
			<div className={"manage-user-container"}>
				<Card>
					<div className={"is-flex is-flex-direction-row container-content"}>
						<div className="mr-6" style={{ width: '50%' }}>
							<div className={"block"}>
								<h3 className={"is-size-3 mb-2 has-text-primary"}>{userData?.username}</h3>
								<p className={"is-size-6"}>{userData?.email}</p>
							</div>
							<div className={"block mt-2"}>
								<p className={"mb-2"}>
									<b>Date Joined:</b> {
										DateTime
											.fromISO(userData?.date_joined!)
											.setZone("Asia/Kolkata")
											.toLocaleString(DateTime.DATETIME_MED)
									}
								</p>
								<p>
									<b>Last Login:</b> {
										DateTime
											.fromISO(userData?.last_login!)
											.setZone("Asia/Kolkata")
											.toLocaleString(DateTime.DATETIME_MED)
									}
								</p>
								<p>
									<span><b>Location:</b>  {userData.country} ({userData.user_tz})</span>
								</p>
								<div className={"field"}>
									<CheckBox text={"Account Email Verified"}
										checked={userData.email_verified}
										onChange={(e) => setUserData({ ...userData, email_verified: e.target.checked })} />
								</div>
							</div>
						</div>
						<div>
							<ul style={{ listStyleType: 'none' }}>	{userData.survey_details.map((survey, index) => (
								<li className="mb-3" key={index} style={{ marginRight: '10px' }}>
									<p><strong>{shortSurveyQ[index]}</strong>: {survey.answer}</p>
								</li>
							))}
							</ul>
							<div className={"field"} style={{ marginLeft: '2rem' }}>
								<CheckBox text={"Email Notifications"}
									checked={userData.send_notification_emails}
									onChange={(e) => setUserData({ ...userData, send_notification_emails: e.target.checked })} />
							</div>
						</div>
					</div>
				</Card>
				<Card className={"mt-4"}>
					<div className={"is-flex is-flex-direction-row is-justify-content-space-between container-content"}>
						<h5 className={"is-size-5"}>User Account Details</h5>
						<div className={"is-flex is-flex-direction-row user-account-btns"}>
							{/* ******************** User Tasks ******************** */}
							{userData.k8_tasks.length > 0 &&
								<button type={"button"}
									className={"button is-primary ml-4 is-small"}
									style={{ cursor: "pointer" }}
									onClick={() => setTasksModal(true)}>
									View Tasks
								</button>
							}
							{/* ******************** User Current Plan Button ******************** */}
							<button type={"button"}
								className={"button is-info ml-4 is-small"}
								style={{ cursor: "default" }}>
								Current Plan: {userData.current_plan}
							</button>
							{/* ******************** Save User Details Button ******************** */}
							<Button color={"success"}
								className={"ml-4 is-small"}
								onClick={() => {
									if (userId) {
										saveUserDataMut.mutate({
											user_id: parseInt(userId),
											email_verified: userData.email_verified,
											email_notifications: userData.send_notification_emails,
											country: userData.country,
											user_tz: userData.user_tz,
											titles_generated: userData.titles_generated,
											total_titles_generated: userData.total_titles_generated,
											articles_generated: userData.articles_generated,
											total_articles_generated: userData.total_articles_generated,
											blog_emails_found: userData.blog_emails_found,
											total_blog_emails_found: userData.total_blog_emails_found,
											total_keywords_generated: userData.total_keywords_generated,
											this_month_keywords_count: userData.this_month_keywords_count,
											this_month_reddit_queries_count: userData.this_month_reddit_queries_count,
											this_month_guest_queries_count: userData.this_month_guest_queries_count,
											this_month_glossary_count: userData.this_month_glossary_count
										});
									} else {
										errorAlert.current?.show("Save failed: User ID value does not exists");
									}
								}}>
								<FontAwesomeIcon icon={"floppy-disk"} />&nbsp;&nbsp;Save Changes
							</Button>
						</div>
						{/* disabled={true} style={{color:"#969696"}}/ */}
					</div>
					<hr className={"dropdown-divider"} />
					<div className={"user-details-form mt-4"}>
						<div className={"user-stats-and-info"}>
							<div className={"info"}>
								<div className={"field"}>
									<label htmlFor={"country"} className={"label"}>Country:</label>
									<div className={"control"}>
										<Input type={"text"}
											id={"country"}
											value={userData.country}
											onChange={e => setUserData({ ...userData, country: e.target.value })} />
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"usertz"} className={"label"}>Timezone:</label>
									<div className={"control"}>
										<Input type={"text"}
											id={"usertz"}
											value={userData.user_tz}
											onChange={e => setUserData({ ...userData, user_tz: e.target.value })} />
										<a href={"https://www.zeitverschiebung.net/en/all-time-zones.html"}
											target={"_blank"}
											rel={"noreferrer"}
											className={"is-size-7"}>Timezone List for Reference</a>
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>GPF Queries Generated this month:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeArticleUsage"}
											value={userData.this_month_guest_queries_count}
											onChange={e => setUserData({ ...userData, this_month_guest_queries_count: parseInt(e.target.value) })} />
									</div>
								</div>
							</div>
							<div className={"user-stats ml-6"}>
								<div className={"field"}>
									<label htmlFor={"titleUsage"} className={"label"}>Titles Generated This Month:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"titleUsage"}
											value={userData.titles_generated}
											onChange={e => setUserData({ ...userData, titles_generated: parseInt(e.target.value) })} />
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeTitleUsage"} className={"label"}>All Time Titles Generated:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeTitleUsage"}
											value={userData.total_titles_generated}
											onChange={e => setUserData({ ...userData, total_titles_generated: parseInt(e.target.value) })} />
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>Glossary Generated This Month:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeArticleUsage"}
											value={userData.this_month_glossary_count}
											onChange={e => setUserData({ ...userData, this_month_glossary_count: parseInt(e.target.value) })} />
									</div>
								</div>
							</div>
							<div className={"user-stats ml-6"}>
								<div className={"field"}>
									<label htmlFor={"articleUsage"} className={"label"}>Articles Generated This Month:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"articleUsage"}
											value={userData.articles_generated}
											onChange={e => setUserData({ ...userData, articles_generated: parseInt(e.target.value) })} />
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>All Time Articles Generated:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeArticleUsage"}
											value={userData.total_articles_generated}
											onChange={e => setUserData({ ...userData, total_articles_generated: parseInt(e.target.value) })} />
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>All Time Glossary Generated:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeArticleUsage"}
											value={userData.total_glossary_words_generated}
											disabled={true} style={{color:"#969696"}} />
									</div>
								</div>
							</div>
							<div className={"user-stats ml-6"}>
								<div className={"field"}>
									<label htmlFor={"articleUsage"} className={"label"}>Keywords Generated This Month:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"articleUsage"}
											value={userData.this_month_keywords_count}
											onChange={e => setUserData({ ...userData, this_month_keywords_count: parseInt(e.target.value) })}
											/>
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>All Time Keywords Generated:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeArticleUsage"}
											value={userData.total_keywords_generated}
											onChange={e => setUserData({ ...userData, total_keywords_generated: parseInt(e.target.value) })}  />
 
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>RPF Queries Generated This Month:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeArticleUsage"}
											value={userData.this_month_reddit_queries_count}
											onChange={e => setUserData({ ...userData, this_month_reddit_queries_count: parseInt(e.target.value) })}  />
									</div>
								</div>
							</div>
							<div className={"user-stats ml-6"}>
								<div className={"field"}>
									<label htmlFor={"titleUsage"} className={"label"}>Email Generated This Month:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"titleUsage"}
											value={userData.blog_emails_found}
											onChange={e => setUserData({ ...userData, blog_emails_found: parseInt(e.target.value) })} />
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeTitleUsage"} className={"label"}>All Time Email Generated:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeTitleUsage"}
											value={userData.total_blog_emails_found}
											onChange={e => setUserData({ ...userData, total_blog_emails_found: parseInt(e.target.value) })} />
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>All Time RPF Queries Generated:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeArticleUsage"}
											value={userData.total_reddit_queries_count}
											disabled={true} 
											style={{color:"#969696"}} />
									</div>
								</div>
							</div>
							<div className={"user-stats ml-6"}>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>Websites connected:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeArticleUsage"}
											value={userData.website_count}
											disabled={true} 
											style={{color:"#969696"}} />
									</div>
								</div>
								<div className={"field"}>
									<label htmlFor={"allTimeArticleUsage"} className={"label"}>All Time GPF Queries Generated:</label>
									<div className={"control"}>
										<Input type={"number"}
											className={"stats-input-width"}
											id={"allTimeArticleUsage"}
											value={userData.total_guest_queries_count}
											disabled={true} 
											style={{color:"#969696"}} />
									</div>
								</div>

							</div >
						</div >
						<div className={"user-stats-and-info mt-4"}>
							{
								(userData.ltd_plan_activation_dates || []).map((plan, index) => (
									<div key={index} className={"info"}>
										<div className={`user-stats ${index > 0 ? "ml-6" : ""}`}>
											<div className={"field"}>
												<label htmlFor={"LTDPlanActivationDates"} className={"label"}>{plan.plan_name} Activated On:</label>
												<div className={"control"}>
													<Input type={"text"}
														style={{ width: "220px" }}
														id={"LTDPlanActivationDates"}
														value={plan.activated_on}
													/>
												</div>
											</div>
										</div>
									</div>
								))
							}
						</div>
					</div >
				</Card >
				{
					userData.all_connected_websites.length > 0 && (
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: userData.all_connected_websites.length === 1 ? '1fr' : 'repeat(3, 1fr)',
								gap: '6px',
							}}
						>
							{userData.all_connected_websites.map((website, index) => (
								<div
									key={website.domain}
									style={{
										gridColumn: userData.all_connected_websites.length === 4 && index === 3 ? '1 / -1' : 'auto',
									}}
								>
									<ManageUserWebsite
										userId={parseInt(userId!)}
										websiteId={website.id}
										name={website.name}
										domain={website.domain}
										logo={website.logo}
										imageSource={website.image_source}
										className={"mt-4 max-w-[96%]"}
										errorAlert={errorAlert}
										successAlert={successAlert}
										refetchUserData={refetchUserData}
									/>
								</div>
							))}
						</div>
					)
				}



				< div className={"block"} >
					{/* <h4 className={"is-size-3 has-text-centered mt-6"}>
						Website Logs
					</h4> */}

					< Card className={"mt-4"} >
						<div className={"log-list"}>
							{userData.website_logs.length === 0 && <p className={"has-text-centered"}>No Logs Available</p>}
							{userData.website_logs.map(log => {
								return (
									<div className={"log-list--item"} key={log.id}>
										<span className={"tag is-primary tag-width has-text-weight-bold is-capitalized"}>
											INFO
										</span>
										<p>
											{/*{log.created_on}*/}
											{DateTime
												.fromISO(log.date)
												.setZone("Asia/Kolkata")
												.toLocaleString(DateTime.DATETIME_MED)}
										</p>
										<p>{log.message}</p>
									</div>
								)
							})}
						</div>
					</Card >
				</div >

				{
					userData.articles.length > 0 && <>
						<Articles articles={userData.articles} />
					</>
				}

				{
					userData.keyword_projects.length > 0 && <>
						<KeywordProjects keywordProjects={userData.keyword_projects} />
					</>
				}

				{
					userData.glossary_projects.length > 0 && <>
						<GlossaryProjects glossaryProjects={userData.glossary_projects} />
					</>
				}

				{
					userData.guest_post_finder_queries.length > 0 && <>
						<GuestPostFinder guest_post_finder_queries={userData.guest_post_finder_queries} />
					</>
				}

				{
					userData.reddit_post_finder_queries.length > 0 && <>
						<RedditPostFinder reddit_post_finder_queries={userData.reddit_post_finder_queries} />
					</>
				}

				{
					userData.total_automation_projects > 0 && <>
						{userId && (
							<AutomationProjects
								automation_projects={userData.automation_projects}
								total={userData.total_automation_projects}
								userId={parseInt(userId)}
							/>
						)}
					</>
				}

				{
					userData.all_website_internal_links_data && userData.all_website_internal_links_data.length > 0 && <>
						{
							userData.all_website_internal_links_data.map(data => (
								<Card className="mt-6">
									<InternalLinks
										InternalLinkData={data.internal_links}
										isCrawling={data.is_website_crawling}
										websiteDomain={data.domain}
									/>
								</Card>
							))
						}
					</>
				}

				{
					userData.blog_finder.length > 0 && <>
						<BlogFinder blogUrls={userData.blog_finder} />
					</>
				}

				{
					userData.blog_finder_project.length > 0 && <>
						{userId && (
							<BlogFinderProject blogProject={userData.blog_finder_project} userId={parseInt(userId)} />
						)}
					</>
				}

				{userData.website_connection_limit !== 0 && <Integration currentPlanName={userData.current_plan} websiteConnectionLimit={userData.website_connection_limit} userID={userId ? userId.toString() : ""} />}

				<Alert type={"danger"} ref={errorAlert} />
				<Alert type={"success"} ref={successAlert} />
			</div >
		</>
	);
}
